// import Page from '@modules/login/pages/default';

// export default Page;

import { useRouter } from 'next/router';
import React from 'react';

export default function Page() {
    const router = useRouter();

    React.useEffect(() => {
        router.replace('/admin/login');
    }, []);

    return <div />;
}
